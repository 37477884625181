body {
    margin: 0;
    font-family: "Book Antiqua", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
* {
    box-sizing: border-box;
    margin: 0;
    color: var(--custom-black);
}

.ReactModal__Overlay--after-open {
    background-color: rgb(137 137 137 / 18%) !important;
    z-index: 2;
}

.ReactModal__Content.ReactModal__Content--after-open.pdf,
.ReactModal__Content.ReactModal__Content--after-open.email {
    background: #727272bd;
}

.ReactModal__Content.ReactModal__Content--after-open.login_model,
.ReactModal__Content.ReactModal__Content--after-open.contactModal,
.ReactModal__Content.ReactModal__Content--after-open.subscriptionModels,
.ReactModal__Content.ReactModal__Content--after-open.blog_modal,
.ReactModal__Content.ReactModal__Content--after-open.ProfileModal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 100vh;
    overflow: auto;
    inset: 0 !important;
    padding: 4rem !important;
    background: #727272bd;
    /* background-image: url(../images/general/homepagebgs.jpg); */
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
}
.ReactModal__Content.ReactModal__Content--after-open.subscriptionModels {
    padding: 4rem 1rem !important;
}
.ReactModal__Content.ReactModal__Content--after-open.pdf_modal {
    /* background: #727272bd; */
    /* background-image: url(../images/general/homepagebgs.jpg); */
    /* background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat; */
}

.ReactModal__Content.ReactModal__Content--after-open.blog_modal {
    overflow: inherit;
    height: auto;
    padding: 0rem !important;
}

.ReactModal__Content.ReactModal__Content--after-open.ProfileModal {
    padding: 2rem 4rem !important;
}

@media (max-width: 1100px) {
    .ReactModal__Content.ReactModal__Content--after-open.login_model {
        margin: 0 auto;
        padding: 0 2rem !important;
    }
    .ReactModal__Content.ReactModal__Content--after-open.ProfileModal {
        padding: 1rem 1rem !important;
    }
}
/* .react-pdf__Page__textContent
{
display: none;
} */
:root {
    --custom-primary-bg: #e9ece6;
    --custom-secondry-bg: #f3f3f2;
    --custom-light-bg: #e9ece6eb;
    --custom-border-color: #c0dfd9;
    --custom-white: #fff;
    --custom-input-bg: #0d0d17;
    --custom-black: #1b203d;
    --custom-btn-bg: #c0dfd9;
    --custom-transparent: #c0dfd900;
    --custom-txt-color: #707070;

    --custom-cream-color: #c0fdd4;
    --custom-muted-text-color: #6c757d;
    --custom-txt-white: #ffffff81;
    --custom-txt-logo: #1ab7fd;
    --custom-topbar-bg: #2a3f5a;
    --custom-footer-txt: #d3dcec;
    --custom-background-grey: rgba(230, 230, 230, 1);
    --custom-light-line_break-color: rgba(245, 245, 245, 1);
    --custom-deep-line_break-color: rgba(140, 140, 140, 1);
    --custom-blue-color: #0275d8;
    --custom-orange-color: goldenrod;
    --color-change-transition: 200ms ease-in-out color;
    /* duetics code */
    --custom-shadow: 0 0 15px -8px #8b8b8b;

    /* fonts sizes */
    --font-74: 74px;
    --font-52: 52px;
    --font-48: 48px;
    --font-42: 42px;
    --font-32: 32px;
    --font-26: 26px;
    --font-22: 22px;
    --font-18: 18px;
    --font-16: 16px;
    --font-14: 14px;
    --font-12: 12px;
    --font-10: 10px;

    /* font family */
    --logo-font: "RushingNightShade", sans-serif;
    --font-1: "Quicksand", sans-serif;
    --font-2: "Merriweather", serif;
}
body {
    background-color: var(--custom-primary-bg);
}
body {
    background-image: url("https://esotericcuriousa.s3.amazonaws.com/static/global/image/wood.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}

*:not(i) {
    /* font-family: "Roboto", sans-serif; */
}

/* root {
    font-size: 16px;
} */

::-webkit-scrollbar {
    background: var(--custom-input-border);
    height: 0px;
    width: 0px;
    margin: 0;
    padding: 0;
    border-radius: 5px;
}

/* Track */

::-webkit-scrollbar {
    background: var(--custom-input-border);
    height: 0px;
    width: 0px;
    margin: 0;
    padding: 0;
    border-radius: 5px;
}

/* Handle */

::-webkit-scrollbar {
    background: var(--custom-orange-color);
    height: 0px;
    width: 0px;
    margin: 0;
    padding: 0;
    border-radius: 5px;
}

/* Handle on hover */

::-webkit-scrollbar {
    background: var(--custom-orange-color);
    height: 0px;
    width: 0px;
    margin: 0;
    padding: 0;
    border-radius: 5px;
}

.pageContentOuter::-webkit-scrollbar {
    background: var(--custom-input-border);
    height: 4px;
    width: 4px;
    margin: 0;
    padding: 0;
    border-radius: 5px;
}

/* Track */
.pageContentOuter::-webkit-scrollbar-track {
    background: var(--custom-input-border);
    height: 4px;
    width: 4px;
    margin: 0;
    padding: 0;
    border-radius: 5px;
}

/* Handle */
.pageContentOuter::-webkit-scrollbar-thumb {
    background: var(--custom-orange-color);
    height: 4px;
    width: 4px;
    margin: 0;
    padding: 0;
    border-radius: 5px;
}

/* Handle on hover */
.pageContentOuter::-webkit-scrollbar-thumb:hover {
    background: var(--custom-orange-color);
    height: 4px;
    width: 4px;
    margin: 0;
    padding: 0;
    border-radius: 5px;
}

.react-pdf__Page__annotations {
    display: none;
}

.annotationLayer {
    display: none;
}

.react-pdf__Page__textContent.textLayer {
    display: none;
}

.stf__item --soft --simple --right {
    top: 10px;
    height: 400px;
}

.stf__wrapper --portrait {
    padding-bottom: 0%;
}

/* .book {
  background-image: url(../../static/images/general/wood3.JPG);
} */

.innerWrapper {
    width: 85%;
    margin: 0 auto;
}

.outerWrapper {
    width: 90%;
    margin: 0 auto;
}

/* .slick-slide.slick-active.slick-cloned {
  display: none;
} */

.innerWrapper {
    background-color: white;
}
